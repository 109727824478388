type RiskLevel = 'high_risk' | 'medium_risk' | 'low_risk';

interface RiskDescriptions {
  [key: string]: {
    [key in RiskLevel]: string;
  };
}

export const riskDescriptions: RiskDescriptions = {
  Payload: {
    high_risk: 'operating with excessive payload.',
    medium_risk: 'operating with elevated payload',
    low_risk: 'operating with minor deviations in payload',
  },
  Speed: {
    high_risk: 'operating with excessive speed',
    medium_risk: 'operating with beyond the speed limit.',
    low_risk: 'operating with minor speed variations',
  },
  Location: {
    high_risk: 'exceeded the proximity range too many times.',
    medium_risk: 'occasionally exceeded the proximity range',
    low_risk: 'minimal deviations from the proximity range',
  },
  Safety: {
    high_risk: 'experiencing critical issues with the safety sensor.',
    medium_risk: '',
    low_risk: '',
  },
  Camera: {
    high_risk: 'experiencing severe malfunctions with camera sensor.',
    medium_risk: '',
    low_risk: '',
  },
  Software: {
    high_risk: 'running outdated software versions.',
    medium_risk: 'running slightly outdated software versions.',
    low_risk: 'running slightly behind on software updates.',
  },
  Authorization: {
    high_risk: 'operating without an authorization mechanism.',
    medium_risk: 'experienced multiple unsuccessful login attempts.',
    low_risk: '',
  },
  Configuration: {
    high_risk: 'operating with outdated configuration.',
    medium_risk: 'operating with outdated configuration.',
    low_risk: '',
  },
  Calibration: {
    high_risk: 'operating without calibrated speed.',
    medium_risk: 'operating without calibrated speed.',
    low_risk: '',
  },
};
